import React, { Component } from "react";

export class FormEditUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      isFormOpenned: false,
      isLoading: this.props.isLoading,
    };
  }

  render() {
    return (
      <>
        <div className="form-horizontal col-xxl-6 col-xl-7 col-md-8 col-sm-8 col-xs-11">
          <div className="form-group">
            <label
              htmlFor="name"
              className="col-xl-4 col-sm-5 col-xs-5 control-label"
            >
              Employee's Full Name:
            </label>
            <div className="col-xl-8 col-sm-7 col-xs-7">
              <input
                className="form-control"
                name="name"
                type="text"
                id="name"
                placeholder="Employee's First Name"
                value={this.props.newUserFullName}
                onChange={this.props.onNewUserFullNameChange}
              />
            </div>
          </div>

          <div className="form-group d-flex justify-content-between justify-content-sm-end align-items-xs-around">
            <button
              type="button"
              className="btn"
              onClick={this.props.handleResetForm}
            >
              Reset
            </button>
            <button
              type="button"
              className="btn"
              onClick={this.props.handleCloseEditForm}
            >
              Close
            </button>
            <button
              type="button"
              className={
                this.props.newUserFullName.length
                  ? `btn btn-primary`
                  : `btn disabled`
              }
              onClick={this.props.handleModalToggle}
            >
              <span
                aria-hidden="true"
                className="vismaicon vismaicon-edit"
              ></span>
              Edit and Save
            </button>
          </div>
          {this.props.children}
        </div>
      </>
    );
  }
}
