import React, { Component, useEffect } from "react";
import { CustomTable, LoadingSpinner } from "../../components";
import { PageTitle } from "../../common";
import {
  useApiRequests,
  showUserInformation,
  showUserHistoryLog,
} from "../../services";

export class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userInfo: [],
      historyLogInfo: [],
      isDataFetching: true,
      allUsers: [],
    };
  }

  async componentDidMount() {
    const { id } = this.props.match.params;
    const { getAllUserInformation } = useApiRequests();
    const userResponse = await getAllUserInformation(id);
    this.setState({
      userInfo: userResponse.UsersInformation,
      historyLogInfo: userResponse.HistoryLogResponse,
      isDataFetching: false,
    });
  }
  render() {
    const { fullName, amountOfKudos } = this.state.userInfo;
    const showUserInformationJSX = showUserInformation(
      ["Full Name", "Kudos Amount"],
      this.state.userInfo
    );
    const showUserHistoryLogJSX = showUserHistoryLog(
      this.state.historyLogInfo, this.state.userInfo
    );
    const showUserDataJSX = this.state.isDataFetching ? (
      <LoadingSpinner />
    ) : (
      <>
        <PageTitle
          title={`Information about the employee: ${fullName} ${amountOfKudos}`}
        />
        <div className="shadow-50">
          <CustomTable customClassName="table table-bordered table-sm table-responsive">
            {showUserInformationJSX}
          </CustomTable>
          {this.state.historyLogInfo.length ? (
            <CustomTable customClassName="table table-bordered table-sm table-responsive">
              {showUserHistoryLogJSX}
            </CustomTable>
          ) : (
            <h3 className="text-center">
              This employee does not have the transaction history
            </h3>
          )}
        </div>
      </>
    );
    return <>{showUserDataJSX}</>;
  }
}
