import React, { Component } from "react";
import { CustomTable, FormAddUser, FormEditUser } from "../../components";
import { PageTitle, LoadingSpinner, CustomModal } from "../../common";
import { withRouter } from "react-router-dom";
import { useApiRequests } from "../../services";
import "./styles.scss";
import axios from "axios";

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allUsersData: [],
      paginatedUsersData: [],
      selectedUserId: null,
      isDataFetching: true,
      isLoading: false,
      isModalOpen: false,
      isFormOpenned: true,
      isEditFormOpenned: false,
      selectedUserToEdit: [],
      newUserFullName: "",
      userType: null,
      currentUserType: localStorage.getItem('userType'),
      searchQuery: "", // New state for search query,
      currentPage: 1,
      totalUsers:0,
      pageSize: 20,
    };
    this.fetchData = this.fetchData.bind(this);
    this.onNewUserFullNameChange = this.onNewUserFullNameChange.bind(this);
    this.handleResetForm = this.handleResetForm.bind(this);
    this.handleUserDelete = this.handleUserDelete.bind(this);
    this.handleUserEditForm = this.handleUserEditForm.bind(this);
    this.handleUserEdit = this.handleUserEdit.bind(this);
    this.handleModalToggle = this.handleModalToggle.bind(this);
    this.handleCloseEditForm = this.handleCloseEditForm.bind(this);
    this.handleExportButton = this.handleExportButton.bind(this);
  }

  async fetchData() {
    const { getAllUsersPaginated, getAllUsers } = useApiRequests();
    const { currentPage } = this.state;
    const responsePaginated = await getAllUsersPaginated(currentPage);
    const responseAllUsersData = await getAllUsers();
    this.setState({ paginatedUsersData: responsePaginated.data, allUsersData: responseAllUsersData.data, isDataFetching: false });
  }

  async componentDidMount() {
    try {
        const { getAllUsers } = useApiRequests();
        const response = await getAllUsers();

        this.setState({
            allUsersData: response.data,
            isDataFetching: false,
        });
    } catch (error) {
        console.error('Error fetching data:', error);
    }
    this.fetchData();
}

  navigateToUserPage = (e, id) => {
    this.props.history.push(`/users/${id}`);
  };

  handleModalToggle = (event) => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
  };

  handlePageChange = (newPage) => {
    this.setState({ currentPage: newPage }, this.fetchData);
};

handleNextPage = () => {
  const { currentPage, pageSize, allUsersData  } = this.state;
  const totalPages = Math.ceil(allUsersData.length / pageSize);

  if (currentPage < totalPages) {
      this.handlePageChange(currentPage + 1);
  }
};

handlePrevPage = () => {
    const { currentPage } = this.state;
    if (currentPage > 1) {
        this.handlePageChange(currentPage - 1);
    }
};

  handleModalClose = () => {
    this.setState({ isModalOpen: false });
  };

  handleUserDelete = async (e) => {
    if (this.state.selectedUserId) {
      try {
        const apiRequests = useApiRequests(); // Use the useApiRequests function to get API functions

        const response = await apiRequests.deleteUserById(this.state.selectedUserId);

        if (response.status === 200) {
          this.setState({ isLoading: true, isModalOpen: false }, () => {
            setTimeout(() => {
              this.setState({
                isLoading: false,
                isEditFormOpenned: false,
                isFormOpenned: true,
              });
              this.fetchData();
            }, 200);
          });
        } else {
          // Handle error if needed
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  handleDeleteUserModal = (e, id) => {
    this.setState({
      selectedUserToEdit: [],
      selectedUserId: id,
      isModalOpen: true,
    });
  };

  handleUserEditForm = (e, user) => {
    this.setState({
      isEditFormOpenned: true,
      isFormOpenned: false,
      newUserFullName: user.fullName,
      selectedUserToEdit: user,
      selectedUserId: null,
    });
  };

  onNewUserFullNameChange = (event, value) => {
    this.setState({
      newUserFullName: event.target.value,
    });
  };

  handleResetForm = () => {
    this.setState({
      newUserFullName: "",
    });
  };
  
  handleCloseEditForm = () => {
    this.setState({
      isEditFormOpenned: false,
      isFormOpenned: true,
    });
  };

  handleUserEdit = async (e) => {
    this.setState({ selectedUserId: null });
    try {
      const apiRequests = useApiRequests(); // Use the useApiRequests function to get API functions
  
      const response = await apiRequests.editUserById(this.state.selectedUserToEdit.id, this.state.newUserFullName);

      if (response.status === 200) {
          this.setState({ isLoading: true, isModalOpen: false }, () => {
            setTimeout(() => {
              this.setState({
                isLoading: false,
                isEditFormOpenned: false,
                isFormOpenned: true,
                selectedUserToEdit: [],
              });
              this.fetchData();
            }, 700);
          });
        };
      return response;
    } catch (error) {
      console.log(error.message);
    }
  };


  handleAddNewUser = async () => {
    try {
      const apiRequests = useApiRequests();

      const response = await apiRequests.postAddNewUser(
        this.state.newUserFullName
      );

      if (response.status === 200) {
        this.props.fetchData();
      } else {
        // Handle error if needed
      }
    } catch (error) {
      console.log(error.message);
    }
  };


  handleExportButton = async (e) => {
    try {
      const apiRequests = useApiRequests();
      await apiRequests.exportData();
    } catch (error) {
      console.log(error.message);
    }
  };

  render() {
    const { 
      userType,
      searchQuery,
      isDataFetching,
      allUsersData,
      paginatedUsersData,
      currentPage,
      pageSize,
    } = this.state;
  
    const totalPages = Math.ceil(allUsersData.length / pageSize);
  
    const pageButtons = Array.from({ length: totalPages }, (_, index) => index + 1);
    const usersToDisplay = searchQuery.trim() ? 
      allUsersData.filter((user) => {
        const fullName = `${user.fullName}`.toLowerCase();
        return fullName.includes(searchQuery.toLowerCase());
      }) : 
      paginatedUsersData;
  
    const showTableWithUsersInformationJSX = isDataFetching ? (
      <LoadingSpinner message="Loading..." />
    ) : (
      <>
        <PageTitle title="Visma - it is all about KUDOS!" />
        <div className="col-10 d-flex flex-column justify-content-center align-items-center">
          {this.state.isFormOpenned && this.state.currentUserType == 1 && (
            <>
              <FormAddUser
                fetchData={this.fetchData}
                isLoading={this.state.isLoading}
              />
              <button
                type="button"
                className="btn"
                onClick={(e) => this.handleExportButton(e)}
              >
                Export to CSV
              </button>
            </>
          )}
          <input
            className="form-group search-group"
            type="text"
            placeholder="Search by Full Name..."
            value={searchQuery}
            onChange={(e) => this.setState({ searchQuery: e.target.value })}
          />
          {!this.state.isFormOpenned && (
            <>
              <FormEditUser
                selectedUserToEdit={this.state.selectedUserToEdit}
                newUserFullName={this.state.newUserFullName}
                onNewUserFullNameChange={this.onNewUserFullNameChange}
                handleResetForm={this.handleResetForm}
                fetchData={this.fetchData}
                handleModalToggle={this.handleModalToggle}
                handleCloseEditForm={this.handleCloseEditForm}
              >
                <CustomModal
                  handleModalToggle={this.handleModalToggle}
                  isModalOpen={this.state.isModalOpen}
                  handleModalClose={this.handleModalClose}
                  handleSubmitForm={this.handleUserEdit}
                  message="Are you sure you want to edit this employee?"
                />
              </FormEditUser>
            </>
          )}
          <div className="custom-shadow mt-64">
            {this.state.selectedUserId &&
              !this.state.selectedUserToEdit.length && (
                <CustomModal
                  handleModalToggle={this.handleModalToggle}
                  isModalOpen={this.state.isModalOpen}
                  handleModalClose={this.handleModalClose}
                  handleSubmitForm={this.handleUserDelete}
                  message="Are you sure you want to delete this employee?"
                />
              )}
            {this.state.isLoading && <LoadingSpinner />}
            <CustomTable customClassName="col-md-8 table table-hover table-active shadow-50">
              <thead>
                <tr>
                  {[
                    "Full Name",
                    "Kudos amount",
                    this.state.currentUserType == 1 && "Operations",
                  ]
                    .filter(Boolean)
                    .map((heading, index) => {
                      return <th key={index}>{heading}</th>;
                    })}
                </tr>
              </thead>
              <tbody>
                {usersToDisplay.map((user) => {
                    return (
                      <tr key={user.id}>
                        <td
                          onClick={this.state.currentUserType == 1 ? (e) => this.navigateToUserPage(e, user.id) : null}
                        >
                          {user.fullName}
                        </td>
                        <td
                          onClick={this.state.currentUserType == 1 ? (e) => this.navigateToUserPage(e, user.id) : null}
                        >
                          {user.amountOfKudos}
                        </td>
                        {this.state.currentUserType == 1 && (
                          <td className="d-flex flex-row justify-content-between">
                            <>
                              <span
                                aria-hidden="true"
                                className="vismaicon vismaicon-dynamic vismaicon-menu vismaicon-edit mr-8"
                                onClick={(e) => {
                                  this.handleUserEditForm(e, user);
                                  window.scrollTo(0, 0);
                                }}
                              ></span>
                              <span
                                aria-hidden="true"
                                className="vismaicon vismaicon-dynamic vismaicon-menu vismaicon-delete"
                                onClick={(e) => this.handleDeleteUserModal(e, user.id)}
                              ></span>
                            </>
                          </td>
                        )}
                      </tr>
                    );
                  })}
              </tbody>
            </CustomTable>
            <div className="d-flex justify-content-center">
              <button
                className="vismaicon vismaicon-dynamic vismaicon-arrow-left-circle"
                onClick={this.handlePrevPage}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              <div className="pagination-buttons">
                {pageButtons.map((pageNumber) => (
                  <button
                    key={pageNumber}
                    className={`pagination-button ${pageNumber === currentPage ? 'active' : ''}`}
                    style={{ height: '4.6rem', width: '4rem' }}
                    onClick={() => this.handlePageChange(pageNumber)}
                  >
                    {pageNumber}
                  </button>
                ))}
              </div>
              <button
                className="vismaicon vismaicon-dynamic vismaicon-arrow-right-circle"
                onClick={this.handleNextPage}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </>
    );
  
    return <>{showTableWithUsersInformationJSX}</>;
  }
}

export const Home = withRouter(HomePage);
