import { createContext, useContext, useReducer } from "react";
import { useHistory } from "react-router-dom";
import { SetUserType } from "../../ClientApp/src/Reducers";
import { useApiRequests } from "../../ClientApp/src/services/fetchApiServices/index";

const apiRequests = useApiRequests();
const initialState = {
  userType: null,
};

const userTypeReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_USERTYPE":
      return {
        ...state,
        userType: action.item,
      };
    default:
      return state;
  }
};
// // Create a custom hook to use the context
// export const useGlobalState = () => {
//   return useContext(GlobalStateContext);
// };
export const UserContext = createContext({
  state: undefined,
  dispatch: undefined,
});
export const useStoreContext = () => useContext(UserContext);

// Provider component that wraps your app
const User = ({ children }) => {
  const history = useHistory();

  const [userType, dispatch] = useReducer(userTypeReducer, initialState);

  const getUserType = async (email) => {
    Promise.resolve(apiRequests.getUserByEmail(email)).then((value) => {
      SetUserType(value.data.userType, dispatch);
      if (value.data.userType) {
        history.push("/");
      }
    });
    const User = await apiRequests.getUserByEmail(email);
  };
  return (
    <UserContext.Provider value={[userType, getUserType]}>
      {children}
    </UserContext.Provider>
  );
};

export default User;
