import React, { Component } from "react";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { ShopSubmitBtn, CustomModal } from "../../common";
import { useApiRequests } from "../../services";

export class RequestKudosTransaction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedEmployee: "",
      kudosAmount: 0,
      reason: "", // Keeping the state variable name as 'reason'
    };
  }

  handleEmployeeChange = (event, value) => {
    this.setState({
      selectedEmployee: value,
    });
  };

  handleAmountChange = (event) => {
    this.setState({ kudosAmount: event.target.value });
  };

  handleReasonChange = (event) => {
    this.setState({ reason: event.target.value });
  };

  handleRequestKudos = () => {
    const { requestKudos } = useApiRequests();
    const { kudosAmount, reason } = this.state;
    requestKudos(kudosAmount, reason); // Sending 'description' instead of 'reason' to backend
    this.props.clearInputs();
  };

  render() {
    return (
      <>
        <div className="form-group d-sm-flex justify-content-start align-items-center">
          <label className="no-padding col-4" htmlFor="kudosAmount">
            Amount of Kudos:
          </label>
          <input
            className="form-control"
            name="kudosAmount"
            type="number"
            value={this.state.kudosAmount > 0 && this.state.kudosAmount}
            onChange={this.handleAmountChange}
            placeholder="Amount of kudos"
            id="kudosAmount"
            min="0"
          />
        </div>
        <div className="form-group d-sm-flex justify-content-start align-items-center">
          <label className="no-padding col-4" htmlFor="reason">
            Reason:
          </label>
          <input
            className="form-control"
            name="reason"
            type="text"
            value={this.state.reason}
            onChange={this.handleReasonChange}
            placeholder="Reason"
            id="reason"
          />
        </div>
        <ShopSubmitBtn
          handleModalToggle={this.props.handleModalToggle}
          title="Submit"
          className={
            this.state.kudosAmount &&
            this.state.reason // Checking both amount and reason for enabling button
              ? `btn btn-primary`
              : `btn disabled`
          }
        />
        <CustomModal
          handleModalToggle={this.props.handleModalToggle}
          isModalOpen={this.props.isModalOpen}
          handleModalClose={this.props.handleModalClose}
          handleSubmitForm={this.handleRequestKudos}
          message="Are you sure you want to submit this request?"
        />
      </>
    );
  }
}
