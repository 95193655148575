import React, { Component } from "react";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { ShopSubmitBtn, CustomModal } from "../../common";
import { useApiRequests } from "../../services";

export class SendKudosBetweenColleaguesTransaction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedReceiverEmployee: "",
      amountOfKudos: 0,
      reason: "",
    };
  }

  onEmployeeChange = (event, value) => {
    this.setState({
      selectedReceiverEmployee: value,
    });
  };

  handleAmountOfKudos = (event) => {
    this.setState({ amountOfKudos: event.target.value });
  };

  handleReason = (event) => {
    this.setState({ reason: event.target.value });
  };

  handleSendKudosToColleague = () => {
    this.props.handleModalToggle();
      const { postSendKudosFromEmployeeToEmployee } = useApiRequests();
      const {
        reason,
        amountOfKudos,
        selectedReceiverEmployee: { id: receiverId },
      } = this.state;
      postSendKudosFromEmployeeToEmployee(
        receiverId,
        amountOfKudos,
        reason
      );
    this.props.clearInputs();
  };

  render() {
    return (
      <>
        <div className="form-group d-sm-flex justify-content-start align-items-center">
          <label className="no-padding col-4">For whom?</label>
          <Autocomplete
             id="combo-box-demo"
              getOptionLabel={(option) => `${option.fullName}`}
              style={{ width: 350 }}
              options={this.props.userData}
              onChange={this.onEmployeeChange}
              renderInput={(params) => (
            <TextField {...params} variant="outlined" />
              )}
              renderOption={(option) => (
          <div>
      <h4>{option.fullName}</h4>
    </div>
  )}
/>
        </div>
        <div className="form-group d-sm-flex justify-content-start align-items-center">
          <label className="no-padding col-4" htmlFor="amountOfKudos">
            Amount of kudos:
          </label>
          <input
            className="form-control"
            name="kudosAmount"
            type="number"
            value={this.state.amountOfKudos > 0 && this.state.amountOfKudos}
            onChange={this.handleAmountOfKudos}
            placeholder="Amount of kudos"
            id="amountOfKudos"
            min="0"
          />
        </div>
        <div className="form-group d-sm-flex justify-content-start align-items-center">
          <label className="no-padding col-4" htmlFor="reason">
            Reason:
          </label>
          <input
            className="form-control"
            name="reason"
            type="text"
            value={this.state.reason}
            onChange={this.handleReason}
            placeholder="Reason"
            id="reason"
          />
        </div>
        <ShopSubmitBtn
          handleModalToggle={this.props.handleModalToggle}
          title="Submit"
          className={
            this.state.selectedReceiverEmployee &&
            this.state.amountOfKudos &&
            this.state.reason
              ? `btn btn-primary`
              : `btn disabled`
          }
        />
        <CustomModal
          handleModalToggle={this.props.handleModalToggle}
          isModalOpen={this.props.isModalOpen}
          handleModalClose={this.props.handleModalClose}
          handleSubmitForm={this.handleSendKudosToColleague}
          message="Are you sure you want to submit this transaction?"
        />
      </>
    );
  }
}
